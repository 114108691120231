import React, { useState } from 'react';

import * as link from '../../utils/helper/link-config';
import * as m from '../../utils/helper/methods';

import logo from '../../assets/white-logo.png';
import world from '../../assets/world-background.png';

import { makeStyles, useTheme } from '@mui/styles';
import { alpha, Box, CssBaseline, Typography, Collapse, AppBar, Toolbar, IconButton, Hidden, Drawer, Divider, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import Menu from './Menu';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        '& .MuiPaper-root': {
            borderRight: 0
        },
        minHeight: `100vh`,
        backgroundImage: `linear-gradient(to bottom, ${theme.palette.secondary.main}, ${alpha(theme.palette.secondary.main, 0.7)}),
                url(${world})`,
        backgroundSize: 'cover',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
    },
    logo: {
        height: 30,
        marginRight: theme.spacing(2),
        cursor: 'pointer'
    },
    appBarContent: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start'
        },
    },
    box: {
        flexGrow: 1,
    }
}));


export default function Layout(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [redirectRoute, setRedirectRoute] = useState(null);

    const handleClickedLogo = () => {
        setRedirectRoute(m.REDIRECT(link.HOME))
    }

    const handleOpenMenu = () => {
        setOpen(!open);
    };

    return (
        <div className={classes.root}>
            {redirectRoute}
            <CssBaseline />
            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    boxShadow: 'none'
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={handleOpenMenu}
                        sx={{
                            mr: 2,
                            color: theme.palette.common.white
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.appBarContent}>
                        <img className={classes.logo} src={logo} onClick={handleClickedLogo}/>
                        {/* <Typography variant="h4" color="primary">
                            ICE 2022
                        </Typography> */}
                    </div>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
            >
                <Drawer
                    variant="temporary"
                    open={open}
                    onClose={handleOpenMenu}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    <Menu closeDrawer={handleOpenMenu} />
                </Drawer>
            </Box>
            <Toolbar />
            <Box
                component="main"
                className={classes.box}
            >

                {props.children}
            </Box>
        </div>

    )
}
