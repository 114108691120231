import { CameraOutlined, OndemandVideoOutlined, VideocamOutlined } from '@mui/icons-material';

import mevoQR from '../../assets/mevo_qr.jpeg';
import obsQR from '../../assets/obs_qr.jpeg';
import multiQR from '../../assets/multi_qr.jpeg';
import mbrQR from '../../assets/nab_mbr_qr.png';

export const MEVO = {
    // GENERAL STREAM INFO
    title: "Mevo Cam", streamname: "streamname1", id: "streamid1", qr: mevoQR, link: "https://demo.nanocosmos.de/nanoplayer/release/nanoplayer-adaptive.html?entry.server=bintu-play.nanocosmos.de&entry.rtmp.url=rtmp://bintu-play.nanocosmos.de/play&entry.rtmp.streamname=1qZn3-zGpfa&entry.info.bitrate=1500&entry.info.width=1280&entry.info.height=720&entry.info.framerate=30&entry2.server=bintu-play.nanocosmos.de&entry2.rtmp.url=rtmp://bintu-play.nanocosmos.de/play&entry2.rtmp.streamname=1qZn3-SuClC&entry2.info.bitrate=800&entry2.info.width=852&entry2.info.height=480&entry2.info.framerate=25&entry3.server=bintu-play.nanocosmos.de&entry3.rtmp.url=rtmp://bintu-play.nanocosmos.de/play&entry3.rtmp.streamname=1qZn3-ZHb42&entry3.info.bitrate=200&entry3.info.width=426&entry3.info.height=240&entry3.info.framerate=15&options.rule=deviationOfMean2&startIndex=2&playback.latencyControlMode=fastadaptive",
    // HOME INFOS
    label: "Mevo", value: "mevo", icon: <VideocamOutlined />
}

export const OBS = {
    // GENERAL STREAM INFO
    title: "OBS", streamname: "streamname2", id: "streamid2", qr: obsQR, link: "https://demo.nanocosmos.de/nanoplayer/release/nanoplayer-adaptive.html?entry.server=bintu-play.nanocosmos.de&entry.rtmp.url=rtmp://bintu-play.nanocosmos.de/play&entry.rtmp.streamname=1qZn3-DFXc8&entry.info.bitrate=1500&entry.info.width=1280&entry.info.height=720&entry.info.framerate=30&entry2.server=bintu-play.nanocosmos.de&entry2.rtmp.url=rtmp://bintu-play.nanocosmos.de/play&entry2.rtmp.streamname=1qZn3-HJd3M&entry2.info.bitrate=800&entry2.info.width=852&entry2.info.height=480&entry2.info.framerate=25&entry3.server=bintu-play.nanocosmos.de&entry3.rtmp.url=rtmp://bintu-play.nanocosmos.de/play&entry3.rtmp.streamname=1qZn3-fEpDi&entry3.info.bitrate=200&entry3.info.width=426&entry3.info.height=240&entry3.info.framerate=15&options.rule=deviationOfMean2&startIndex=2&playback.latencyControlMode=fastadaptive",
    // HOME INFOS
    label: "OBS", value: "obs", icon: <CameraOutlined />
}

export const OBS2b = {
    // GENERAL STREAM INFO
    title: "OBS2", streamname: "streamname2", id: "streamid2", qr: obsQR, link: "https://bintu-cloud-frontend.nanocosmos.de/playout/eac3a25b-19e3-4d34-aa5e-bbd626075358?vtrans1.id=8f8f915f-1a4f-4114-8062-e160ae470221&vtrans1.bitrate=800&vtrans1.fps=25&vtrans1.width=852&vtrans1.height=480&vtrans2.id=3cfe19c8-abae-4fba-85c5-cbafad4ad941&vtrans2.bitrate=400&vtrans2.fps=25&vtrans2.width=640&vtrans2.height=360&startIndex=0",
    // HOME INFOS
    label: "OBS", value: "obs", icon: <CameraOutlined />
}

export const MULTI = {
    // GENERAL STREAM INFO
    title: "Multiplayer", streamname: "-", id: "-", qr: multiQR, link: "https://nanocosmos-dev.github.io/demo/nanoplayer/release/nanoplayer-multi.html?bintu.apikey=TdoKNXBVykMlr1aiLN6awR3dnIUErhKnHm5dTv9zWpJByGmnjtoNggaWGTtWkryjv8PcOQZRnjiTlRTC&bintu.tags=1.%20Transcode&playback.latencyControlMode=fastadaptive",
    // HOME INFOS
    label: "Multiplayer", value: "multi", icon: <OndemandVideoOutlined />
}

export const MBR = {
    // GENERAL STREAM INFO
    title: "Multiplayer", streamname: "-", id: "-", qr: mbrQR, link: "https://demo.nanocosmos.de/nanoplayer/mbrdemo/nanoplayer-mbr.html?qry=1&entry.server.websocket=wss://bintu-splay.nanocosmos.de/h5live/authstream&entry.server.hls=https://bintu-splay.nanocosmos.de/h5live/authhttp/playlist.m3u8&entry.rtmp.url=rtmp://bintu-splay.nanocosmos.de/splay&entry.rtmp.streamname=nNCcf-3ki5Xentry.info.bitrate=1500&entry.info.width=1280&entry.info.height=720&entry.info.framerate=30&entry.security.token=9c982424d50b23da756c87fc3c5eb850&entry.security.options=1&entry.security.expires=1667311320&entry2.server.websocket=wss://bintu-splay.nanocosmos.de/h5live/authstream&entry2.server.hls=https://bintu-splay.nanocosmos.de/h5live/authhttp/playlist.m3u8&entry2.rtmp.url=rtmp://bintu-splay.nanocosmos.de/splay&entry2.rtmp.streamname=nNCcf-wDMBj&entry2.info.bitrate=640&entry2.info.width=852&entry2.info.height=480&entry2.info.framerate=30&entry2.security.token=d17403294803b52d54aff7fdfc22dd32&entry2.security.options=1&entry2.security.expires=1667311320&entry3.server.websocket=wss://bintu-splay.nanocosmos.de/h5live/authstream&entry3.server.hls=https://bintu-splay.nanocosmos.de/h5live/authhttp/playlist.m3u8&entry3.rtmp.url=rtmp://bintu-splay.nanocosmos.de/splay&entry3.rtmp.streamname=nNCcf-Wh4Ax&entry3.info.bitrate=250&entry3.info.width=480&entry3.info.height=270&entry3.info.framerate=15&entry3.security.token=b7dbf2a9edc50bcfac5dcb426dd85779&entry3.security.options=1&entry3.security.expires=1667311320&options.rule=deviationOfMean2&startIndex=1&showAcButtons=true&",
    // HOME INFOS
    label: "Multiplayer", value: "multi", icon: <OndemandVideoOutlined />

}


export const DEMOS = [MBR];
