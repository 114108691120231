import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';


import { ThemeProvider, createTheme } from '@mui/material/styles';
import { StylesProvider, createGenerateClassName } from '@mui/styles';

import Layout from './components/layout/Layout';
import Router from './components/layout/Router';


document.body.style.margin = 0;
document.body.style.padding = 0;

const generateClassName = createGenerateClassName({
  productionPrefix: 'nanoStream-cloud',
  seed: 'nanoStream-cloud'
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#ed7d0e',
    },
    secondary: {
      main: '#020305',
      light: '#001F3A'
    }
  },
  typography: {
    fontFamily: [
      'Source Sans Pro',
      // "sans-serif"
    ],
    h1: {
      fontWeight: 600,
      fontFamily: ['Source Code Pro'],
    },
    h3: {
      fontWeight: 700,
      fontFamily: ['Source Code Pro'],
    },
    h4: {
      fontWeight: 700,
      fontFamily: ['Source Code Pro'],
      textTransform: 'uppercase',
      fontSize: '24px'
    },
    h5: {
      fontWeight: 700,
      fontFamily: ['Montserrat'],
      fontSize: '24px',
      // textTransform: 'uppercase'
    }
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          background: 'rgba(237, 125, 14, 0.2)',
          borderRadius: '5px',
          fontWeight: 700,
          color: '#ed7d0e',
        },
        clickableColorPrimary: {
          '&:hover': {
            backgroundColor: '#ed7d0e',
            color: '#ffffff',
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: 'inherit',
          borderRadius: '20px',
          boxShadow: 'none',
        },
        containedPrimary: {
          backgroundColor: '#ed7d0e',
          color: '#ffffff',
          '&:hover': {
            boxShadow: 'none',
          }
        },
        containedSecondary: {
          backgroundColor: '#ed7d0e33',
          color: '#ed7d0e',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#ed7d0e66',
            color: '#ed7d0e',
          }
        },
        outlinedPrimary: {
          borderColor: '#ed7d0e',
          color: '#ed7d0e',
        },
        outlinedSecondary: {
          borderColor: '#3e2516',
          color: '#3e2516',
          '&:hover': {
            borderColor: '#3e251690'
          }
        },
      },
    },
  }
});

console.log(theme);

const app = (
  <StylesProvider generateClassName={generateClassName}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Layout>
          <Router />
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  </StylesProvider>
)

ReactDOM.render(app, document.getElementById('show-frontend'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
