// FRONTEND REDIRECTS
export const HOME = '/';
export const PLAYOUT = '/playout';
export const RESOURCES = '/resources';
export const ABOUT = '/about';
export const FREE_TRIAL = 'https://bintu-cloud-frontend.nanocosmos.de/auth?signup';

// SOCIAL MEDIA
export const GITHUB = 'https://github.com/nanostream';
export const FACEBOOK = 'https://www.facebook.com/nanocosmos.net/';
export const YOUTUBE = 'https://www.youtube.com/channel/UCsWDxJax9j3GrMDufHBwgKA';
export const INSTAGRAM = 'https://www.instagram.com/nanovideo/';
export const LINKEDIN = 'https://www.linkedin.com/company/nanocosmos-gmbh/';
export const WEBSITE = 'https://info.nanocosmos.de/';
export const TWITTER = 'https://twitter.com/nanovideo';
export const BLOG = 'https://www.nanocosmos.de/blog/';

// HELPER LINKS
export const WEBCASTER_DOCS = 'https://docs.nanocosmos.de/docs/webrtc/nanostream_webrtc_introduction/';
export const NANOPLAYER_RELEASE_SRC = 'https://demo.nanocosmos.de/nanoplayer/api/release/nanoplayer.4.min.js?20200819'
export const DOCS = 'https://docs.nanocosmos.de/';
export const FEEDBACK = 'https://www.nanocosmos.de/feedback';
export const BINTU = 'https://bintu.nanocosmos.de/';
export const READ_ME = 'https://docs.nanocosmos.de/nanostream-cloud-dashboard-readme.html';
export const CONTACT = 'https://www.nanocosmos.de/contact';
export const NANO_SUPPORT = 'https://www.nanocosmos.de/support';
export const H5LIVE_DOCS = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_api/';
export const TOKEN_SECURITY_IP = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_token_security/#c-with-a-client-ip';
export const TOKEN_SECURITY_REFERER = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_token_security/#d-with-a-custom-tag-and-a-referer';
export const TOKEN_SECURITY_DOCS = 'https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_token_security';
export const METRICS_LOGIN = 'https://metrics.nanocosmos.de/#/login'
export const METRICS_DOCS = 'https://docs.nanocosmos.de/docs/cloud/analytics/';
export const METRICS_API = 'https://metrics.nanocosmos.de/api/doc/';
export const IMPRINT = 'https://www.nanocosmos.de/terms';