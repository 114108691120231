import React, { useEffect, useState } from 'react';

import * as resource from '../utils/helper/resources';
import * as link from '../utils/helper/link-config';

import world from '../assets/world-background.png';

import { makeStyles } from '@mui/styles';
import { alpha, CardMedia, Card, Divider, Grid, Typography, CardContent, IconButton, Button } from '@mui/material';
import { Facebook, GitHub, Instagram, Language, LinkedIn, Twitter, YouTube } from '@mui/icons-material';

import SectionHeader from '../components/global/SectionHeader';
import DemoAnnouncer from '../components/home/DemoAnnouncer';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        padding: theme.spacing(4)
    },
    divider: {
        background: theme.palette.primary.main,
        marginTop: `${theme.spacing(1)} !important`
    },
    item: {
        [theme.breakpoints.up('xs')]: {
            marginTop: `${theme.spacing(2)} !important`,
        },
        [theme.breakpoints.up('md')]: {
            marginRight: `${theme.spacing(2)} !important`,
        },

        // margin: `${theme.spacing(2, 2, 0, 0)} !important`
    },
    button: {
        marginTop: `${theme.spacing(1)} !important`
    },
    card: {
        background: 'transparent !important',
        border: `1px solid ${theme.palette.primary.main}`
    }
}));

export default function Resources(props) {
    const classes = useStyles();
    const [redirectRoute, setRedirectRoute] = useState(null);
    const socialMedia = [
        { icon: (<GitHub fontSize="inherit" />), link: link.GITHUB },
        { icon: (<Language fontSize="inherit" />), link: link.WEBSITE },
        { icon: (<Facebook fontSize="inherit" />), link: link.FACEBOOK },
        { icon: (<YouTube fontSize="inherit" />), link: link.YOUTUBE },
        { icon: (<Twitter fontSize="inherit" />), link: link.TWITTER },
        { icon: (<Instagram fontSize="inherit" />), link: link.INSTAGRAM },
        { icon: (<LinkedIn fontSize="inherit" />), link: link.LINKEDIN }
    ]

    document.title = "Resources | nanoStream Cloud"

    const handleClickedSocialMediaLink = (sLink) => () => {
        window.open(sLink, '_blank')
    }

    return (
        <Grid container className={classes.root}>
            {redirectRoute}
            <Grid item xs={12}>
                <SectionHeader
                    title="Resources"
                    underline="Find here further informations about nanocosmos and nanoStream Cloud."
                />
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12} className={classes.item}>
                {/* SOCIAL MEDIA ROW  */}
                <div className={classes.socialMediaWrapper}>
                    {
                        socialMedia.map((s, i) => (
                            <IconButton
                                key={i}
                                color="primary"
                                size="medium"
                                onClick={handleClickedSocialMediaLink(s.link)}
                            >
                                {s.icon}
                            </IconButton>
                        ))
                    }
                </div>
            </Grid>
            {
                resource.RES.map((r, i) => (
                    <Grid className={classes.item} item xs={12} md={"auto"} key={i}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography color="primary" variant="h5">
                                    {r.title}
                                </Typography>
                                <Typography color="white" variant="body1">
                                    {r.underline}
                                </Typography>
                                <Button className={classes.button} variant="contained" color="primary" href={r.link} download={r.download ? r.link : null}>
                                    {r.button}
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            }

        </Grid>

    )
}
