import React, { useState } from 'react';

import * as demos from '../../utils/helper/demos';
import * as m from '../../utils/helper/methods';
import * as link from '../../utils/helper/link-config';

import { makeStyles } from '@mui/styles';
import { alpha, Button, Divider, Grid, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import { ArrowRightAlt, CameraOutlined, OndemandVideoOutlined, VideocamOutlined } from '@mui/icons-material';

import CopyButton from '../global/CopyButton';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        background: theme.palette.common.white,
        borderRadius: theme.spacing(2),
        padding: theme.spacing(4)
    },
    qrCode: {
        display: 'block',
        margin: `${theme.spacing(2)} auto`,
        [theme.breakpoints.up('xs')]: {
            width: 300,
        },
        maxWidth: '100%',
    },
    tabWrapper: {
        marginTop: theme.spacing(1),
        textAlign: 'center'
    },
    button: {
        marginTop: `${theme.spacing(2)} !important`
    }
}));

const TabPanel = (props) => {
    const classes = useStyles();
    const info = props.data;
    const [redirectRoute, setRedirect] = useState(null);

    const handleOpenPlayer = () => {
        // setRedirect(m.REDIRECT(`${link.PLAYOUT}/${info.streamname}`));
        window.open(info.link, '_target')
    }

    return (
        <div className={classes.tabWrapper}>
            {redirectRoute}
            {/* <Typography variant="h4" color="primary">
                {info.title}
            </Typography> */}
            {/* <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ padding: 0 }} />
                            <TableCell sx={{ padding: 0 }} />
                            <TableCell sx={{ padding: 0 }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            [
                                // { label: "Stream Type", value: info.title, copy: false },
                                { label: "Streamname", value: info.streamname, copy: true },
                                { label: "Stream ID", value: info.id, copy: true }
                            ].map((row, i) => (
                                <TableRow>
                                    <TableCell width={100}>
                                        <Typography variant="button" color="textSecondary">
                                            {row.label}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {row.value}
                                        </Typography>
                                    </TableCell>
                                    {
                                        row.copy && row.value !== "-"
                                            ?
                                            <TableCell>
                                                <CopyButton iconButton copy={row.value} />
                                            </TableCell>
                                            : <TableCell />
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer> */}
            <img className={classes.qrCode} src={info.qr} />
            <Button
                className={classes.button}
                variant="contained"
                endIcon={<ArrowRightAlt />}
                onClick={handleOpenPlayer}
            >
                Open nanoStream H5Live Player
            </Button>
        </div >
    )
}


export default function DemoAnnouncer(props) {
    const classes = useStyles();
    const [selectedItem, setSelectedItem] = useState(Object.values(demos.DEMOS)[0].value)

    const handleSelectNew = (event, newItem) => {
        setSelectedItem(newItem);
        console.log(demos.DEMOS)
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12} className={classes.root}>
                {/* <Tabs
                    centered
                    textColor="primary"
                    value={selectedItem}
                    onChange={handleSelectNew}
                    className={classes.tabList}
                >
                    {
                        demos.DEMOS.map((link, i) => (
                            <Tab
                                sx={{
                                    color: '#fff'
                                }}
                                key={i}
                                value={link.value}
                                label={link.label}
                                icon={link.icon}
                            // iconPosition="start"
                            />
                        ))
                    }
                </Tabs>
                <Divider /> */}
                {
                    demos.DEMOS.map((d, i) => (
                        <div key={d.value} hidden={d.value !== selectedItem} >
                            <TabPanel data={d} />
                        </div>
                    ))
                }
            </Grid>
        </Grid>

    )
}
