import React, { useState } from 'react';

import * as link from '../utils/helper/link-config';

import notFound from '../assets/NotFound.png';

import { makeStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import { HeadsetMicOutlined, LiveTvOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        textAlign: 'center',
        padding: theme.spacing(2),
        background: theme.palette.common.white,
    },
    image: {
        width: 600,
        maxWidth: '100%',
        margin: '0 auto',
        display: 'block'
    },
    title: {
        fontSize: '30px !important'
    },
    button: {
        margin: `${theme.spacing(1, 1, 0, 0)} !important`
    }
}));

export default function NotFound(props) {
    const classes = useStyles();

    document.title = "Oops, Page Not Found | nanoStream Cloud Show Demo";

    const redirect = (link) => () => {
        window.open(link);
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <img src={notFound} className={classes.image} />
                <Typography className={classes.title} variant="h5" color="primary">
                    Page not found
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    Oops, we could not find the page you are looking for.
                </Typography>
                <div className={classes.buttonWrapper}>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={redirect(link.FREE_TRIAL)}
                        startIcon={<LiveTvOutlined />}
                    >
                        Get a 7-days free trial
                    </Button>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        onClick={redirect(link.CONTACT)}
                        startIcon={<HeadsetMicOutlined />}
                    >
                        Talk to us
                    </Button>
                </div>

            </Grid>
            <Grid item xs={12}>

            </Grid>
        </Grid>

    )
}
