import flyer from '../../assets/Flyer_NAB.pdf'
import guide from '../../assets/From_Local_To_Global.pdf'

export const RES = [
    {
        title: "nanoStream Cloud Overview",
        underline: "Find on this flyer an overview of all the features nanoStream Cloud offers.",
        link: flyer,
        button: "Download",
        download: true
    },
    {
        title: "Get a free trial",
        underline: "Sign up and get a 7-days free trial1",
        link: "https://www.nanocosmos.de/blog/freetrial",
        button: "Start now",
        download: false
    },
    {
        title: "Contact Us",
        underline: "Get in touch with us.",
        link: "https://www.nanocosmos.de/v6/contact?",
        button: "Contact us now",
        download: false
    },
    {
        title: "Our Website",
        underline: "Have a look at our website and see what we offer.",
        link: "https://info.nanocosmos.de/nanostreamcloud/",
        button: "Read more",
        download: false
    },
    {
        title: "Documentation",
        underline: "Visit our documentation and start, embed and play a live stream instant.",
        link: "https://docs.nanocosmos.de/",
        button: "Read more",
        download: false
    },
    {
        title: "Our Blog",
        underline: "Learn more about the nanoStream Cloud on our blog.",
        link: "https://www.nanocosmos.de/blog/",
        button: "Read more",
        download: false
    },
]