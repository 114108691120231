import React, { Fragment, useEffect, useState } from 'react';

import * as link from '../utils/helper/link-config';
import * as bintu from '../utils/bintu/api-requests';

import notLive from '../assets/stream-not-live.png';
import notFound from '../assets/stream-not-found.png';

import { makeStyles } from '@mui/styles';
import { Grid, Typography, Button, Collapse } from '@mui/material';
import { HeadsetMicOutlined, LiveTvSharp } from '@mui/icons-material';

import SectionHeader from '../components/global/SectionHeader';
import Loading from '../components/global/Loading';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        padding: theme.spacing(2)
    },
    image: {
        width: 600,
        maxWidth: '100%',
        margin: '0 auto',
        display: 'block',
        marginBottom: theme.spacing(2)
    },
    item: {
        textAlign: 'center',
    },
    button: {
        margin: `${theme.spacing(1, 1, 0, 0)} !important`
    }
}));

const InfoMessage = (props) => {
    const classes = useStyles();

    const redirect = (link) => () => {
        window.open(link);
    }

    return (
        <Fragment>
            {
                props.notFound
                    ? <img className={classes.image} src={notFound} />
                    : <img className={classes.image} src={notLive} />
            }
            <Typography variant="h5" color="primary" >
                {
                    props.notFound
                        ? "Oops, we could not find the stream you are looking for."
                        : "Oops, the stream you are looking for is not live."
                }
                <br />
            </Typography>
            <Typography variant="body1" color="textSecondary">
                If you think this was a mistake, contact our support.
            </Typography>
            <div className={classes.buttonWrapper}>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={redirect(link.FREE_TRIAL)}
                    startIcon={<LiveTvSharp />}
                >
                    Get a Free Trial
                </Button>
                <Button
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    onClick={redirect(link.CONTACT)}
                    startIcon={<HeadsetMicOutlined />}
                >
                    Talk to us
                </Button>
            </div>
        </Fragment>
    )
}

export default function Playout(props) {
    const classes = useStyles();
    let streamid = props.match.params.id
    const [loading, setLoading] = useState(true);
    const [stream, setStream] = useState(false);

    document.title = "nanoStream H5Live Playout | nanoStream Cloud Show Demo";

    const getStreams = () => {
        let data = {
            streamid
        }
        bintu.getStream(data)
            .then((response) => {
                if (response.success) {
                    console.log(response.data)
                    setStream(response.data)
                    setLoading(false)
                }
            }).catch((error) => {
                setStream(false);
                setLoading(false);
            })
    }

    useEffect(() => {
        if (!stream) {
            getStreams();
        }
    }, [])

    return (
        <Grid container className={classes.root}>
            {
                loading
                    ? <Loading />
                    :
                    <Fragment>
                        {
                            stream
                                ?
                                <Grid item xs={12} className={classes.item}>
                                    {
                                        stream.state === "live"
                                            ?
                                            <Fragment>

                                                <SectionHeader
                                                    title="nanoStream H5Live Player"
                                                    underline="This is a H5Live Playout"
                                                />
                                                <Collapse in={stream}>
                                                    "You live"
                                                </Collapse>
                                            </Fragment>
                                            : <InfoMessage />
                                    }
                                </Grid>
                                :
                                <Grid item xs={12} className={classes.item}>
                                    <InfoMessage notFound />
                                </Grid>
                        }
                    </Fragment>
            }
        </Grid>

    )
}
