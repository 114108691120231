import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import * as link from '../../utils/helper/link-config';

import { makeStyles } from '@mui/styles';
import { alpha, List, ListItem, Typography } from '@mui/material';
import { AttachFileOutlined, GridOnOutlined, InfoOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        background: theme.palette.primary.main,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    label: {
        paddingLeft: theme.spacing(0.5)
    },
    active: {
        color: `${theme.palette.common.white} !important`,
        background: `${alpha(theme.palette.common.white, 0.2)} !important`
    },
    list: {
        paddingLeft: `${theme.spacing(1)} !important`,
        paddingRight: `${theme.spacing(1)} !important`,
    },
    menuItem: {
        color: theme.palette.secondary.light,
        textDecoration: 'none',
        padding: theme.spacing(1, 0.5),
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: theme.spacing(1),
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.common.white
        }
    }
}));

export default function Menu(props) {
    const classes = useStyles();
    const [topMenu, setMenu] = useState([
        { title: "Home", link: link.HOME, icon: <GridOnOutlined /> },
    ])

    const [bottomMenu] = useState([
        // { title: "About", link: link.ABOUT, icon: <InfoOutlined /> },
        { title: "Resources", link: link.RESOURCES, icon: <AttachFileOutlined /> },
    ]);


    return (
        <div className={classes.root}>
            <List>
                {
                    topMenu.map((item) => (
                        <ListItem className={classes.list} key={item.title}>
                            <NavLink
                                onClick={props.closeDrawer}
                                to={item.link}
                                className={classes.menuItem}
                                isActive={(match, location) => {
                                    if (match) {
                                        return match.isExact
                                    }
                                }}
                                activeClassName={classes.active}
                            >
                                {item.icon}
                                <Typography className={classes.label} variant="body1">
                                    {item.title}
                                </Typography>
                            </NavLink>
                        </ListItem>
                    ))
                }
            </List>
            <List>
                {
                    bottomMenu.map((item) => (
                        <ListItem className={classes.list} key={item.title}>
                            <NavLink
                                onClick={props.closeDrawer}
                                to={item.link}
                                className={classes.menuItem}
                                isActive={(match, location) => {
                                    if (match) {
                                        return match.isExact
                                    }
                                }}
                                activeClassName={classes.active}
                            >
                                {item.icon}
                                <Typography className={classes.label} variant="body1">
                                    {item.title}
                                </Typography>
                            </NavLink>
                        </ListItem>
                    ))
                }
            </List>
        </div>
    )
}
