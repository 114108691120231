import React from 'react';

import { makeStyles } from '@mui/styles';
import { Grid, Typography, Button } from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    container: {
        margin: '0 !important',
        padding: '0 !important'
    },
    root: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    title: {
        display: 'inline-block',
        color: theme.palette.primary.main,
        lineHeight: 1.1,
        marginRight: `${theme.spacing(2)} !important`,
        // textTransform: 'capitalize'
    },
    smallTitle: {
        display: 'inline-block',
        color: theme.palette.primary.main,
        lineHeight: 1.1,
        fontWeight: '700 !important',
        marginRight: `${theme.spacing(2)} !important`,

    }
}));

export default function SectionHeader(props) {
    const classes = useStyles();


    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.root}>
                {
                    props.small
                        ?
                        <Typography variant="body1" className={classes.smallTitle}>
                            {props.title}
                        </Typography>
                        : <Typography variant="h5" className={classes.title}>
                            {props.title}
                        </Typography>
                }
                <div className={classes.buttonWrapper}>
                    {
                        props.button && props.extend
                            ?
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                endIcon={<ArrowRightAlt />}
                                onClick={props.clicked}
                            >
                                {props.button}
                            </Button>
                            : null
                    }
                    {
                        props.button && props.interact
                            ?
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                endIcon={props.icon}
                                onClick={props.clicked}
                            >
                                {props.button}
                            </Button>
                            : null
                    }
                </div>
            </Grid>
            {
                props.underline
                    ?
                    <Grid item xs={12}>
                        {
                            props.small
                                ?
                                <Typography variant="body2" color="textSecondary">
                                    {props.underline}
                                </Typography>
                                :
                                <Typography variant="body1" color="white">
                                    {props.underline}
                                </Typography>
                        }

                    </Grid>
                    : null
            }
        </Grid>
    )
}
