import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import * as link from '../../utils/helper/link-config';

import { makeStyles } from '@mui/styles';
import Home from '../../pages/Home';
import NotFound from '../../pages/404';
import Playout from '../../pages/Playout';
import Resources from '../../pages/Resources';

const useStyles = makeStyles((theme) => ({
    root: {
    },
}));

export default function Router(props) {
    const classes = useStyles();

    return (
        <Switch>
            <Route exact path={link.HOME} component={Home} />
            <Route exact path={link.RESOURCES} component={Resources} />
            {/* <Route exact path={`${link.PLAYOUT}/:id`} component={Playout} /> */}
            <Route component={NotFound} /> {/* NOT FOUND */}
        </Switch>
    )

}